.audio-player-container {
  position: relative;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 235px; /* Ensure a fixed height for consistency */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; /* Ensure the image covers the full height */
  background-size: cover; /* Ensure the image covers the container */
  background-position: center; /* Center the image */
  z-index: 1;
}

.audio-controls {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 235px;
  text-align: center;
  justify-content: center;
  backdrop-filter: blur(4px);
}

.play-pause-btn {
  justify-content: center;
  cursor: pointer;
  width: 70px;
  border: none;
  background: transparent;
}

.seek-bar {
  width: 80%;
  bottom: 10px;
  position: absolute;
  cursor: pointer;
}
