.group-image-section{
    position: relative;
    width: 100px;
    margin: 0px auto;
    margin-bottom: 15px;
}
.group-image-section img{
    width: 100px;
    height: 100px;
    border-radius: 50%;

}
.group-image-section button{
    position: absolute;
    bottom: 0;
    right: 0;
    border: 0;
    border-radius: 50%;
    background-color: #FF0098;
    border-radius: 50%;
    color: #fff;
    height: 30px;
    font-size: 18px;
    width: 30px;
}