.custom-audio-player-container {
  position: relative;
  width: 100%;
  height: 400px; /* Same height as video and image */
  border-radius: 12px; /* Consistent border radius */
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1); /* Match the media background */
}

.custom-audio-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
  filter: blur(8px); /* Add blur to background image for consistency */
  opacity: 0.8; /* Slight transparency */
}

.custom-audio-controls {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: center;
  backdrop-filter: blur(6px); /* Add blur effect */
  background-color: rgba(0, 0, 0, 0.5); /* Dark background for contrast */
  color: #fff; /* Ensure white text for contrast */
}

.custom-audio-play-pause-btn {
  display: flex;
  justify-content: center;
  cursor: pointer;
  width: 60px;
  height: 60px;
  border: none;
  background: transparent;
  color: #fff; /* Ensure button is visible */
  font-size: 24px; /* Increase button size */
}

.custom-audio-seek-bar {
  width: 80%;
  height: 5px;
  border-radius: 5px;
  background-color: #fff;
  position: absolute;
  bottom: 20px;
  cursor: pointer;
  opacity: 0.9; /* Light opacity for seek bar */
}

.custom-media-container {
  width: 100%;
  height: 400px; /* Consistent height for all media types */
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}
